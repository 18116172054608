import { FormControl, FormGroup } from "@angular/forms";
import { Component } from '@angular/core';

@Component({
  template: ''
})
export class FormValidations {

  constructor() { }

  ngOnInit(): void {
  }

  static number(control: FormControl) {
    const value = control.value;
    const regex = /^[0-9]\d*$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { number: true }
  }

  static positiveNumber(control: FormControl) {
    const value = control.value;
    const regex = /^[0-9]\d*$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { positiveInteger: true}
  }
  static money(control: FormControl) {
    const value = control.value;
    const regex = /^\.?(?!-)\d+(?:\.\d{1,2})?$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { money: true }
  }
  

  static processNumber(control: FormControl) {
    const value = control.value;
    const regex = /^\d{9}$/;

    if (value === null || value === '') {
      return null
    }
    return true
    //return regex.test(value) ? null : { processNumber: true }
  }

  static nuit(control: FormControl) {
    const value = control.value;
    const regex = /^\d{9}$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { nuit: true }
  }

  static nib(control: FormControl) {
    const value = control.value;
    const regex = /^\d{21}$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { nib: true }
  }

  static phone(control: FormControl) {
    const value = control.value;
    const regex = /^[0-9]\d*$/;

    if (value === null || value === '') {
      return null
    }

    return regex.test(value) ? null : { phone: true }
  }

  static lessThanToday(control: FormControl) {
    let value = control.value;
    let today = new Date();
    
    

    if (value === null) {
      return null
    }


    if (value) {

      let inputDate = new Date(`${value.year}-${value.month}-${value.day}`);     
      today = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)

      if (inputDate.getTime() <= today.getTime()) {
        return null;
      } else {
        return { lessThanToday: true }
      }
    } else {
      return null;
    }
  }

  static greaterOrEqualThanMinDate(minDate: string, maxDate: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let minControl = group.controls[minDate];
      let maxControl = group.controls[maxDate];
      if(minControl.value !== null && maxControl.value!== null){
        let min = new Date(`${minControl.value.year}-${minControl.value.month}-${minControl.value.day}`);     
        let max = new Date(`${maxControl.value.year}-${maxControl.value.month}-${maxControl.value.day}`);     
        if (min.getTime() > max.getTime()) {
          return { greaterOrEqualThanMinDate: `Data máxima deve ser igual ou maior do que data mínima`, lessThanOrEqualThanMaxDate: `Data mínima deve ser igual ou menor do que data máxima`  };
        }
      }
      return {};
    }
  }
  
  static getErrorMsg(fieldName: string, validatorName: string, validatorValue?: any) {
    const config = {
      'required': `${fieldName} é obrigatório!`,
      'min': `O valor mínimo de ${fieldName} é ${validatorValue?.min}!`,
      'max': `O valor máximo de ${fieldName} é ${validatorValue?.max}!`,
      'minlength': `${fieldName} precisa ter no mínimo ${validatorValue?.requiredLength} caracteres!`,
      'maxlength': `${fieldName} precisa ter no máximo ${validatorValue?.requiredLength} caracteres!`,
      'number': `${fieldName} deve ser um valor númerico!`,
      'positiveInteger': `${fieldName} deve ser um valor númerico inteiro positivo!`,
      'money': `${fieldName} deve ser um valor númerico positivo com ou sem casas decimais (duas casas decimais)!`,
      'processNumber': `${fieldName} deve ser parecido com 351/CRV/PGR/2022!`,
      'nuit': `${fieldName} deve conter 9 dígitos!`,
      'nib': `${fieldName} deve conter 21 dígitos!`,
      'phone': `${fieldName} deve conter apenas números!`,
      'email': `${fieldName} deve ser válido!`,
      'lessThanToday': `${fieldName} deve ser menor ou igual à data de hoje!`,
      'greaterOrEqualThanMinDate': `Data máxima deve ser igual ou maior do que data mínima`,
      'lessThanOrEqualThanMaxDate': `Data mínima deve ser igual ou menor do que data máxima`
    }

    return config[validatorName];
  }

}
